import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Docs({ data }) {
  const docs = data.strapiPages

  const docsHTML = docs.Content.RichText.replace(
    /<br\/\>\s/gi,
    "\n &nbsp;"
  ).replace(/<br \/\>\s/gi, "\n &nbsp;")
  return (
    <Layout>
      <Seo title={docs.PageTitle} />
      <div>
        <h1>{docs.PageTitle}</h1>
        <div className="text-muted updated-text"><em>Update at {docs.updated_at}</em></div>
        <p className="mb-5">{docs.Description}</p>

        <ReactMarkdown
          children={docsHTML}
          plugins={[gfm]}
          // allowedElements={["br"]}
        />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!) {
    strapiPages(Slug: { eq: $slug }) {
      PageTitle
      Slug
      Description
      Content {
        RichText
      }
      updated_at
    }
  }
`
